/* global app */

'use strict';

app.directive('jsonValidator', [
    '$rootScope',
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ctrl) {
                function customValidator (ngModelValue) {
                    var valid = false;

                    ngModelValue = ngModelValue.trim();

                    if (ngModelValue === '') {
                        valid = true;
                    }

                    else if (ngModelValue.indexOf('{') === 0) {
                        valid = true;
                    }

                    else {
                        try {
                            JSON.parse(ngModelValue);
                            valid = true;
                        } catch (e) {
                            valid = false;
                        }
                    }

                    ctrl.$setValidity('jsonValidator', valid);

                    return ngModelValue;
                }

                ctrl.$parsers.push(customValidator);
            }
        };
    }
]);
