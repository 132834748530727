/* global app, angular */

'use strict';

app.utils = {
    clone: function (object) {
        if (typeof object !== 'object') {
            return {};
        }
        return JSON.parse(JSON.stringify(object));
    },

    filter: function () {
        var args = Array.prototype.slice.call(arguments),
            filter = args.shift();

        return function (item) {
            var keep = false;

            if (app.settings.global && !app.settings.global.show_deleted_entries && !item.enabled) {
                return false;
            }

            if (!filter) {
                return true;
            }

            for (var i = 0; i < args.length; i++) {
                var field = args[i];

                if (item[field] && item[field].toLowerCase().indexOf(filter.toLowerCase()) > -1) {
                    keep = true;
                }
            }

            return keep;
        };
    },

    collection: {
        delete: function (array, object) {
            return array.filter(function (row) {
                return row !== object;
            });
        }
    },

    form: {
        error: function (form, field, type) {
            return form.$submitted && form[field].$error[type || 'required'];
        },

        invalid: function (form, field) {
            return form.$submitted && form[field].$invalid;
        },

        invalidClass: function (form, field) {
            // console.log('form', form);
            if (!form || !form[field]) {
                return;
            }
            return { 'form-error': form.$submitted && form[field].$invalid };
        },

        invalidMessages: function (errors, field) {
            if (errors[field]) {
                return errors[field];
            }

            return [];
        },

        prepareErrors: function (form, errors) {
            form.$apiErrors = false;

            if (!errors.data) {
                return;
            }

            if (errors.data.fields) {
                errors = errors.data.fields;

                form.$nonFieldError = null;
                form.$apiErrors = true;

                angular.forEach(errors, function (errors, field) {
                    angular.forEach(errors, function (error) {
                        error = error.replace(/ /g, '-');
                        form[field].$setValidity(error, false);
                    });
                });
            }

            if (errors.data && errors.data.message) {
                form.$nonFieldError = errors.data.message;
            }
        }
    },
    jwt: {
        decode: function (token) {
            var splits = token.split('.'),
                base64,
                payload,
                json;

            if (splits.length !== 3) {
                throw new Error('Bad token');
            }

            base64 = splits[1];
            payload = atob(base64);

            if (!payload) {
                throw new Error('Bad token');
            }

            json = JSON.parse(payload);

            if (!json || !json.exp) {
                throw new Error('Bad token');
            }

            return json;
        },

        hasExpired: function(token) {
            var decoded = app.utils.jwt.decode(token),
                now = new Date().getTime() / 1000;

            return decoded.exp < now;
        }
    }
};

