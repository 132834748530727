/* global app */

'use strict';

app.factory('websocket', [ "$rootScope",
    function ($rootScope) {
        var ws = null;
        var action_listeners = {};
        var pending_messages = [];
        var _service = null;

        return _service = {
            users: {},
            init: function () {
                var ws_url = app.settings.api.url;
                if (ws_url.indexOf("http") === -1) {
                    var protocol = "ws";
                    if (window.location.protocol === "https:")
                        protocol = "wss";
                    ws_url = protocol + "://" + window.location.host + ws_url;
                } else {
                    ws_url = ws_url.replace("http", "ws");
                }

                console.log("ws_url", ws_url);
                ws = new ReconnectingWebSocket(ws_url);
                ws.open();
                ws.onopen = function() {
                    // console.log("ws.connected", ws.readyState);
                    setTimeout(function() {
                        if (pending_messages.length) {
                            pending_messages.forEach(_service.send);
                        }
                        pending_messages = [];
                    });
                };
                ws.onmessage = function(msg) {
                    var payload;
                    try {
                        payload = JSON.parse(msg.data);
                        if (payload.action && action_listeners[payload.action]) {
                            // console.log("dispatch", payload.action, payload.data, action_listeners[payload.action]);
                            action_listeners[payload.action](payload.data);
                            if (!$rootScope.$$phase) { $rootScope.$digest(); }
                        } else if (typeof payload.route !== "undefined" && payload.user) {
                            if (payload.route === null) {
                                delete _service.users[payload.ws_id];
                            } else if (!_service.users[payload.ws_id]) {
                                _service.users[payload.ws_id] = {
                                    route: payload.route,
                                    firstname: payload.user.firstname,
                                    lastname:  payload.user.lastname
                                };
                            } else {
                                _service.users[payload.ws_id].route = payload.route;
                            }
                            if (!$rootScope.$$phase) { $rootScope.$digest(); }
                            // console.log("new route", _service.users);

                        } else {
                            // console.log("skip", payload.action, payload.data);
                        }
                    } catch (e) {
                        // console.error(e);
                        // console.log(">ws.msg", msg);
                    }
                };
            },
            me: function(me) {
                this.send({
                    me: me
                });
            },
            send: function (msg) {
                console.log("ws.state", ws.readyState, msg);
                try {
                    ws.send(JSON.stringify(msg));
                } catch (e) {
                    pending_messages.push(msg);
                }

            },
            route: function(url) {
                action_listeners = {};
                this.send({route: url});
            },
            action: function(action, data) {
                this.send({
                    action: action,
                    data:   data
                });
            },
            on_action: function(action, cb) {
                action_listeners[action] = cb;
            }
        };
    }
]);
