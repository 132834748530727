/* global app */

'use strict';

app.directive('message', function () {
    return {
        scope: true,
        restrict: 'E',
        templateUrl: '/directives/message'
    };
});
