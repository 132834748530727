/* global app */

'use strict';

app.controller('AuthLogoutCtrl', [
    '$scope',
    '$state',
    'Auth',
    function ($scope, $state, Auth) {
        Auth.logout();
        $state.go(app.settings.redirect.afterLogout);
    }
]);
