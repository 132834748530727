/* global app */

'use strict';

app.directive('formErrorFieldClass', function () {
    return {
        scope: true,
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, el, attr, ngModel) {
            $scope.$watch(function () {
              return ngModel.$touched;
            }, function () {
                if (ngModel.$invalid && ngModel.$touched) {
                    el.addClass('form-error');
                } else {
                    el.removeClass('form-error');
                }
           });
        }
    };
});
