/* global app */

'use strict';

app.directive("compareDateInf", function () {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareDateInf"
        },
        link: function(scope, element, attributes, ngModel) {
            ngModel.$validators.compareDateInf = function (modelValue) {
                ngModel.$setValidity('compare-date-inf', new Date(modelValue) <= new Date(scope.otherModelValue));

                return modelValue;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});
