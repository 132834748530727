/* global app */

'use strict';

app.service('Xls', [
    '$http',
    function ($http) {
        var Xls = {
            getXlsContent: function (data) {
                return $http.post(app.settings.api.url + 'create.xlsx', data).then(function (response) {
                    console.log('XLS Response', response);

                    return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                }).catch(function (error) {
                    console.log('XLS error', error);

                    return { error: error };
                });
            },

            downloadXls: function (filename, data) {
                return Xls.getXlsContent(data).then(function (content) {
                    var link = document.createElement("a");
                    link.download = filename;
                    link.href = content;
                    link.click();

                    return content;
                });
            }
        };

        return Xls;
    }
]);
