/* global app */

'use strict';

app.service('User', [
    '$http',
    function ($http) {
        var User = {
            fetch: function (id) {
                var endpoint = id ? 'users/' + id + '/' : 'users/';

                return $http.get(app.settings.api.url + endpoint).then(function (response) {
                    console.log('User fetch ok', response);

                    return response && response.data;
                }).catch(function (error) {
                    console.log('User fetch error', error);

                    return { error: error };
                });
            },
            fetchProjects: function (id) {
                var endpoint = 'users/' + id + '/projects/';

                return $http.get(app.settings.api.url + endpoint).then(function (response) {
                    console.log("User's projects", response);

                    return response && response.data;
                }).catch(function (error) {
                    console.log("User's projects error", error);

                    return { error: error };
                });
            },
            fetchFiles: function (id) {
                var endpoint = 'users/' + id + '/files/';

                return $http.get(app.settings.api.url + endpoint).then(function (response) {
                    console.log("User's files", response);

                    return response && response.data;
                }).catch(function (error) {
                    console.log("User's files error", error);

                    return { error: error };
                });
            },
        };

        return User;
    }
]);
