/* global app , _*/

'use strict';

app.controller('GsmaTurnoverCtrl', [
    '$rootScope',
    '$scope',
    '$q',
    '$state',
    'ngDialog',
    'File',
    'User',
    'Project',
    function ($rootScope, $scope, $q, $state, ngDialog,File,User,Project) {
        $scope.filters = {
            project : null,
            text: '',
            status: 'all'
        };

        $scope.accepted = null;
        $scope.projects = [];
        $scope.entries  = null;

        $scope.has = {
            meeting_space: true,
            food_and_beverage: true,
            technical: true,
            misc: true,
        };

        var load = function(){
            $scope.accepted  = [];
            $scope.loading = true;

            User.fetchProjects($rootScope.me._id).then(function (projects) {
                return $q.all( projects.map(function(project){
                    $scope.projects.push(project);
                    return Project.turnover(project._id).then( function (p){
                        _.each( p.files, function(f){
                            if((!f.submit_to_gsma || (f.gsma_status === "Accepted")) && (f.status !== 'closed')) {
                                f.project_id = project._id;
                                f.comm = p.organisator_retro_commission;
                                compute(f);
                            }
                        });
                    });
                }));
            })
            .then( function(){$scope.loading = false} );
        };

        $scope.getValue = function (entry, field) {
            return entry[$scope.filters.status][field];
        };

        $scope.getSum = function (entry) {
            var total = 0;
            _.each($rootScope.FINANCIAL_TYPES, function (name, service) {
                total += $scope.getValue(entry,service);
            });
            return total;
        };

        $scope.getTotal = function (field,results) {
            var total = 0;
            if (!results) {
                return;
            }
            results.forEach(function (entry) {
                if(field === 'total'){
                    total += $scope.getSum(entry);
                }else if(field === 'orga_comm'){
                    total += $scope.getSum(entry) * entry.comm / 100 ;
                }else{
                    total += $scope.getValue(entry, field);
                }
            });
            return total;
        };

        var compute = function(file){
            if (!$scope.entries) {
                $scope.entries = [];
            }

            var entry = {
                _id: file._id,
                name: file.name,
                company: file.company.name,
                final_client: file.final_client,
                reference: file.references,
                sent: file.date_sent,
                due: file.sold_out_date,
                all: {},
                accepted: {},
                pending: {},
                comm : file.comm,
                project_id : file.project_id,
                comment_organisator : file.comment_organisator
            };

            _.each($rootScope.FINANCIAL_TYPES, function (name, service) {
                var val = file[service];
                entry['all'][service] = val || 0;
                entry['accepted'][service] = (file.confirmed_services && file.confirmed_services[service] ? val : 0) || 0;
                entry['pending'][service] = (file.confirmed_services && file.confirmed_services[service] ? 0 : val) || 0;
            });
            $scope.entries.push(entry);
        };

        load();

        $scope.openComment = function (file) {
            $scope.editedFile = file;

            ngDialog.openConfirm({
                template: 'comment-edition',
                scope: $scope,
                width: '800px'
            }).then(function () {
                // Save here
                file.comment_organisator = $scope.editedFile.comment_organisator;
                $rootScope.loading(true);
                File.changeComment(file, file.comment_organisator).then(function () {
                    $rootScope.loading(false);
                });
             }, function () {});
        };

        $scope.calculateColspan = function () {
            var cols = 0;

            _.each($scope.has, function (value) {
                if (value) {
                    cols++;
                }
            });

            return cols;
        };

        $scope.hasServices = function () {
            return !!($scope.calculateColspan());
        };

        $scope.entryFilter = function(entry) {
            return (!$scope.filters.project || entry.project_id === $scope.filters.project._id);
        };
    }
]);
