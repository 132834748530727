/* global app */

'use strict';

app.service('Pdf', [
    '$http',
    function ($http) {
        var Pdf = {
            getPdfContent: function (id, data) {
                console.log('post pdf in ', id, data);
                return $http.post(app.settings.api.url + 'files/' + id + '/invoice_preview.pdf', data).then(function (response) {
                    console.log('Pdf Response', response);

                    return 'data:application/pdf;base64,' + response.data;
                }).catch(function (error) {
                    console.log('Pdf error', error);

                    return { error: error };
                });
            },

            downloadPdf: function (filename, id, data) {
                return Pdf.getPdfContent(id, data).then(function (content) {
                    var link = document.createElement("a");
                    link.download = filename;
                    link.href = content;
                    link.click();

                    return content;
                });
            }
        };

        return Pdf;
    }
]);
