/* global app */

'use strict';

app.controller('ClientFilesCtrl', [
    '$rootScope',
    '$scope',
    '$q',
    'User',
    function ($rootScope, $scope, $q, User) {
        var requests = [];

        $scope.entries = null;

        var splitFile = function(file){
            var splitted = [];
            var file_data = _.omit(file, [ 'venues']);

            _.each(file.venues, function(venue){
                var item = _.clone(file_data);
                item.venue = venue.name;
                item._id = file._id;
                if(venue.dates){
                    item.from = venue.dates.from;
                    item.to = venue.dates.to;
                }
                splitted.push(item);
            });
            return splitted;
        }

        User.fetchFiles($rootScope.me._id).then(function (files) {
            $scope.entries = null;
            var computed = [];
            $rootScope.loading(false);
            _.each(files, function(file){
                var splited = splitFile(file);
                computed = computed.concat(splited);
            });
            $scope.entries = computed;
            $rootScope.loading(false);
        });
        $rootScope.loading(true);
    }
]);

