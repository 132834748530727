/* global app */

'use strict';

app.settings = {
    api: {
        url: '/api/',
        bearer: ''
    },
    redirect: {
        afterLogin:  'projects-selection',
        afterLogout: 'login',
        afterReject: 'login'
    }
};
