/* global app, angular */

'use strict';

app.directive('formErrorMessages', function () {
    return {
        scope: true,
        restrict: 'E',
        link: function ($scope) {
            // $scope.$parent.$watch(function ($scope) {
            //     return $scope.form.$error;
            // }, function () {
            //     var errorCount = 0;

            //     angular.forEach($scope.form, function (item) {
            //         if (item && item.$error && Object.keys(item.$error).length) {
            //             errorCount++;
            //         }
            //     });

            //     $scope.errorCount = errorCount;
            // }, true);

            // $scope.$parent.$watch(function ($scope) {
            //     return $scope.form.$apiNonFieldErrors;
            // }, function () {
            //     if ($scope.form.$apiNonFieldErrors) {
            //         $scope.errorNonFieldCount = $scope.form.$apiNonFieldErrors.length;
            //     } else {
            //         $scope.errorNonFieldCount = 0;
            //     }
            // }, true);

            // $scope.$parent.$watch(function ($scope) {
            //     return $scope.form.$apiFieldErrors;
            // }, function () {
            //     if ($scope.form.$apiFieldErrors) {
            //         $scope.errorFieldCount = Object.keys($scope.form.$apiFieldErrors).length;
            //     } else {
            //         $scope.errorFieldCount = 0;
            //     }
            // }, true);
        },
        template: function () {
            var html = ''; // <pre>{{ form | json }}</pre>';

            html += '<ul class="alert alert-error" ng-if="form.$submitted && (errorCount || errorNonFieldCount || errorFieldCount )">';
            html += '    <li ng-repeat="error in form.$apiNonFieldErrors track by $index">{{ error }}</li>';
            html += '    <li ng-if="errorCount">There {{ errorCount > 1 ? "are" : "is" }} {{ errorCount }} error{{ errorCount > 1 ? "s" : "" }} in this form.</li>';
            html += '    <li ng-if="errorFieldCount">There {{ errorFieldCount > 1 ? "are" : "is" }} {{ errorFieldCount }} API error{{ errorFieldCount > 1 ? "s" : "" }} in this form.</li>';
            html += '</ul>';

            return html;
        }
    };
});
