/* global app */

'use strict';

app.directive('formErrorFieldMessages', function () {
    return {
        restrict: 'E',
        template: function (el, attr) {
            var html      = '',
                field     = 'form.' + attr.field,
                apiField  = 'form.$apiFieldErrors.' + attr.field;

            html += '<ul class="form-errors" ng-if="(form.$submitted || ' + field + '.$error && ' + field + '.$touched)">';

            html += '    <li ng-if="' + field + '.$error.required">  This field is required</li>';
            html += '    <li ng-if="' + field + '.$error.minlength"> This field is too short</li>';
            html += '    <li ng-if="' + field + '.$error.maxlength"> This field is is too long</li>';
            html += '    <li ng-if="' + field + '.$error.email">     This field required a valid email</li>';
            html += '    <li ng-if="' + field + '.$error.pattern">   This field seems to be invalid</li>';

            html += '    <li ng-if="' + apiField + '" ng-repeat="error in ' + apiField + ' track by $index">';
            html += '       Server said "{{ error }}"';
            html += '    </li>';

            html += '</ul>';

            // html += '<ul class="form-errors" ng-if="!' + apiField + ' && (form.$submitted || ' + field + '.$error && ' + field + '.$touched)">';
            // html += '    <li ng-if="' + field + '.$error.required">  This field is required</li>';
            // html += '    <li ng-if="' + field + '.$error.minlength"> This field is too short</li>';
            // html += '    <li ng-if="' + field + '.$error.maxlength"> This field is is too long</li>';
            // html += '    <li ng-if="' + field + '.$error.email">     This field required a valid email</li>';
            // html += '    <li ng-if="' + field + '.$error.pattern">   This field seems to be invalid</li>';
            // html += '</ul>';
            // html += '<ul class="form-errors" ng-if="' + apiField + '">';
            // html += '    <li ng-repeat="error in ' + apiField + ' track by $index">{{ error }}</li>';
            // html += '</ul>';

            return html;
        }
    };
});
