/* global app */

'use strict';

app.controller('GsmaHomeCtrl', [
    '$rootScope',
    '$scope',
    function ($rootScope, $scope) {

    }
]);
