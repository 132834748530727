/* global app */

'use strict';

app.directive('focusMe', [ '$timeout', function ($timeout) {
  return {
    scope : {
      trigger : '@focusMe'
    },
    link: function (scope, element) {
      scope.$watch('trigger', function (value) {
        if (value === "true") {
          $timeout(function () {
            element[0].focus();
          });
        }
      });
    }
  };
}]);
