/* global app */

'use strict';

app.service('ErrorInterceptor', [
    '$injector',
    '$rootScope',
    '$q',
    function ($injector, $rootScope, $q) {
        return {
            requestError: function (request) {
                console.log('ErrorInterceptor request', request);
            },

            responseError: function (response) {
                console.log('ErrorInterceptor response', response);

                if ([ 401, 402, 403 ].indexOf(response.status) > -1) {
                    $injector.get('$state').go(app.settings.redirect.afterReject);
                }

                if ([ 404, 405, 500 ].indexOf(response.status) > -1) {
                    alert("Api error " + response.status + ". Please retry later...");
                }

                if (response.status === -1) {
                    if (!$rootScope.connectionDown) {
                        $rootScope.connectionDown = true;
                        $rootScope.openConnectionError();
                    }
                } else {
                    $rootScope.connectionDown = true;
                }

                return $q.reject(response);
            }
        };
    }
]);
