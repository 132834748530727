/* global app */

'use strict';

var data = [
    [
        {
            value: 'First',
            bold: true
        },
        {
            value: 'Last',
            bold: true
        },
        {
            value: 'Bride',
            bold: true
        },
        {
            value: 'Birthdate',
            bold: true
        },
        {
            value: 'Fortune',
            bold: true
        }
    ],

    [
        {
            type: 'string',
            value: 'Jay',
        },
        {
            type: 'string',
            value: 'Pitt',
            bold: true,
        },
        {},
        {
            type: 'date',
            value: '20/01/1974',
        },
        {
            type: 'money',
            value: 7512345.10
        }
    ],
    [
        {
            type: 'string',
            value: 'Angelina',
        },
        {
            type: 'string',
            value: 'Longère',
            bold: true,
        },
        {
            type: 'string',
            value: 'Jolie',
            bold: true,
        },
        {
            type: 'date',
            value: '20/12/1972',
        },
        {
            type: 'money',
            bold: true,
            value: 50000000.55
        }
    ]
];

app.controller('HomeCtrl', [
    '$rootScope',
    '$scope',
    'Xls',
    function ($rootScope, $scope, Xls) {

        // XLS

        $scope.xls = function () {
            Xls.downloadXls('prout.xls', data).then(function (content) {

            });
        };
    }
]);
