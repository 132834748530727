/* global app, _ */

'use strict';

app.controller('ClientFilesDetailsCtrl', [
    '$rootScope',
    '$scope',
    '$stateParams',
    'File',
    'Company',
    'User',
    'ngDialog',
    function ($rootScope, $scope, $stateParams, File, Company,User, ngDialog) {

        // Get company's info

        File.fetch($stateParams.id, { populate: true }).then(function (response) {
            $scope.file = response;
            $scope.pm = response.project_manager;
            $scope.company = response.billing_company;

            _.each($scope.file.bookings, function(booking) {
                var service_id = booking.service;
                _.find($scope.file.project.suppliers, function(supplier) {
                    if (supplier.supplier === booking.supplier) {
                        _.find(supplier[booking.type], function(service) {
                            if (service._id === service_id) {
                                booking.service = service;
                                booking.supplier = supplier;
                                return true;
                            }
                        });
                        return true;
                    }

                });
            });
            console.log($scope.file);

        });

        File.fetchPlanning($stateParams.id, null, "day").then(function(rooms) {
            console.log("planning response", rooms);
            $scope.rooms = rooms;
            if (rooms.length) {
                $scope.days = _.map(rooms[0].dates, function(date) { return {date: new Date(date.date)}; } );
            }
            $scope.venues = _.chain(rooms).groupBy("supplier._id").values().value();
            console.log("$scope.venues", $scope.venues);

        });

        $scope.has_service = function(meeting_space_id, service_type, unit) {
            if (typeof unit === "string" || !unit)
              return false;

            return _.find($scope.file.bookings, function(booking) {
                return booking.type === service_type &&
                    unit.date.from <= booking.date.from &&
                    booking.date.to <= unit.date.to &&
                    booking.meeting_space === meeting_space_id;
            });

        };

        var get_services = function(meeting_space_id, unit) {
            console.log("get_services", meeting_space_id, unit);
            return _.filter($scope.file.bookings, function(booking) {
                return unit.date.from <= booking.date.from &&
                    booking.date.to <= unit.date.to &&
                    booking.meeting_space === meeting_space_id;
            });
        };


        $scope.openServiceSummary = function (meeting_space_id, unit) {
            $scope.selected_services = get_services(meeting_space_id, unit);

            console.log("selected_services", $scope.selected_services);

            ngDialog.open({
                template: 'dialog-services-summary',
                scope: $scope,
                width: '1000px'
            });
        };
    }
]);

