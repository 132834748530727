/* global app */

'use strict';

app.controller('AuthLoginCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    'Auth',
    function ($rootScope, $scope, $state, Auth) {
        $scope.data = {
        };

        $scope.login = function (form, data) {
            if (!form || form.$valid) {
                $rootScope.loading(true);

                Auth.login(data.login, data.password).then(function (response) {
                    $rootScope.loading(false);

                    if (response.error) {
                        app.utils.form.prepareErrors(form, response.error);
                    } else {
                        // GSMA
                        if (response.type === 'organisator') {
                            return $state.go('gsma-reminder');
                        // CLIENT
                        } else {
                            return $state.go('client-files');
                        }
                    }
                });
            }
        };
    }
]);
