/* global app */

'use strict';

app.factory('localFactory', [
    function () {
        return {
            set: function (key, value) {
                return localStorage.setItem(key, JSON.stringify(value));
            },

            get: function (key) {
                var value = localStorage.getItem(key);

                try {
                    return JSON.parse(value);
                } catch(e) {
                    return value;
                }
            },

            destroy: function () {
                return localStorage.clear();
            }
        };
    }
]);
